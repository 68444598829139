<template>
    <section>
        <div>
            
        </div>
    </section>
</template>

<script>
    /* eslint-disable */
    import Vue from "vue";
    import moment from 'moment';

    export default Vue.component("Geo", {
        data(){
            return {
                coordenadas: ''
            }
        },
        mounted() {
            this.getGeoDelayed(5);
        },
        props: [
        "item",
        "topicName",
        "claseGeneral"
        ],
        methods: {
            getGeo(){
                if (!"geolocation" in navigator) {
                    console.log("Tu navegador no soporta el acceso a la ubicación. Intenta con otro");
                    this.coordenadas =  "#ErrorNotSupport#";
                    this.$emit('getLocation', this.coordenadas);
                    this.$emit("footerDisabled", false);
                }

                const onUbicacionConcedida = (ubicacion) => {
                    console.log("Ubicación: ", ubicacion);
                    this.coordenadas = ubicacion.coords.latitude + ' , ' + ubicacion.coords.longitude;
                    this.$emit('getLocation', this.coordenadas);
                    this.$emit("footerDisabled", false);
                }

                const onErrorDeUbicacion = (err) => {
                    console.log("Error obteniendo ubicación: ", err.code);
                    this.coordenadas = '#ErrorNoPermission#';
                    this.$emit('getLocation', this.coordenadas);
                    this.$emit("footerDisabled", false);
                }

                const opcionesDeSolicitud = {
                    enableHighAccuracy: true, // Alta precisión
                    maximumAge: 0, // No queremos caché
                    timeout: 10000 // Esperar solo 5 segundos
                };
                // Obtener ubicacion
                navigator.geolocation.getCurrentPosition(onUbicacionConcedida, onErrorDeUbicacion, opcionesDeSolicitud);
            },
            getGeoDelayed(delay){                
                this.$emit("footerDisabled", true);
                setTimeout(this.getGeo, delay * 1000);
            }
        }
    })
</script>