<template id="modalQualtrics">
    <div>
        <v-btn v-bind:id="idQuizButton" :aria-label="item.data.cardAction.title.trim()"
            v-bind:data-title="item.data.cardAction.value.trim()"
            class="btn btn-chat-bot btn-chat-bot-act options-register-user qualtrics" v-bind:class="[claseGeneral]"
            :data-ref="refId" @click.stop="dialog = true">
            {{ item.data.cardAction.title.trim() }}
        </v-btn>
        <v-dialog v-model="dialog" max-width="400" persistent >
            <v-card>
                <v-card-title style="height: 40px !important;background-color: rgb(238, 19, 44) !important;">
                </v-card-title>
                <v-card-text style="padding-right: 0%;padding-left: 6%;">
                    <v-btn @click="dialog = false"
                        style="left: 78%;position: absolute;top: .3%;border: 1px solid white;color: white;background-color: rgb(238, 19, 44) !important;z-index: 2;"><v-icon>mdi-close</v-icon></v-btn>
                    <iframe :src="url" width="350" height="800" title="Encuesta" 
                        style="width: 100%;transform: scale(0.94);transform-origin: 0px 0px;top: 0;left: 0;height: 100vh;border: none;padding-bottom: 100px;"></iframe>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from "vue"
import QualtrixData from "@/models/qualtrixData";

export default Vue.component("EncuestaQualtrix", {
    props: [
        "item",
        "claseGeneral",
        "refId"
    ],
    data() {
        return {
            modalQuatrix: false,
            idQuizButton: "btnShowQuiz",
            dataQ: new QualtrixData(),
            visibleSurvey: false,
            url: this.createUrl(),
            dialog: false
        }
    },
    methods: {
        createUrl() {
            let dataQualtrics = this.obtainQualtrixData();

            var parametersToSend = '?Canal=Web&Servicio=ClaroBot';

            if (dataQualtrics.Usuario != null && dataQualtrics.Usuario != '') parametersToSend = parametersToSend + '&Usuario=' + dataQualtrics.Usuario;
            if (dataQualtrics.Fecha != null && dataQualtrics.Fecha != '') parametersToSend = parametersToSend + '&Fecha=' + dataQualtrics.Fecha;
            if (dataQualtrics.Hora_inicio != null && dataQualtrics.Hora_inicio != '') parametersToSend = parametersToSend + '&Hora_inicio=' + dataQualtrics.Hora_inicio;
            if (dataQualtrics.Hora_fin != null && dataQualtrics.Hora_fin != '') parametersToSend = parametersToSend + '&Hora_fin=' + dataQualtrics.Hora_fin;
            if (dataQualtrics.Motivo_visita1 != null && dataQualtrics.Motivo_visita1 != '') parametersToSend = parametersToSend + '&Motivo_visita1=' + dataQualtrics.Motivo_visita1;
            if (dataQualtrics.Motivo_visita2 != null && dataQualtrics.Motivo_visita2 != '') parametersToSend = parametersToSend + '&Motivo_visita2=' + dataQualtrics.Motivo_visita2;
            if (dataQualtrics.ID_Conversacion != null && dataQualtrics.ID_Conversacion != '') parametersToSend = parametersToSend + '&ID_Conversacion=' + dataQualtrics.ID_Conversacion;
            if (dataQualtrics.Tipo_Cliente != null && dataQualtrics.Tipo_Cliente != '') parametersToSend = parametersToSend + '&Tipo_Cliente=' + dataQualtrics.Tipo_Cliente;

            let url = String(localStorage.getItem('surveyLink')) + parametersToSend.replaceAll(" ", "%20");
            return url;
        },
        obtainQualtrixData() {
            this.dataQ = new QualtrixData();
            this.modalQuatrix = true;

            this.dataQ.Usuario = this.item.email;
            this.dataQ.Canal = 'Web';
            this.dataQ.Servicio = 'ClaroBot';
            this.dataQ.Fecha = new Date().toLocaleDateString().replaceAll("/","-");
            this.dataQ.Hora_inicio = this.item.dateInit;
            this.dataQ.Hora_fin = this.formatAMPM(new Date());
            this.dataQ.Motivo_visita1 = this.item.motivoVisita;
            this.dataQ.Motivo_visita2 = String(localStorage.getItem('buttonNameForSurvey'));
            this.dataQ.ID_Conversacion = this.item.guidKeyChat;
            this.dataQ.Tipo_Cliente = this.item.clientType;

            return this.dataQ;
        },
        formatAMPM(date) {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            let strTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;
            return strTime;
        },
        base64ArrayBuffer(arrayBuffer) {
            let base64 = '';
            const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

            const bytes = new Uint8Array(arrayBuffer);
            const byteLength = bytes.byteLength;
            const byteRemainder = byteLength % 3;
            const mainLength = byteLength - byteRemainder;

            let a, b, c, d;
            let chunk;

            // Main loop deals with bytes in chunks of 3
            for (let i = 0; i < mainLength; i = i + 3) {
                chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

                a = (chunk & 16515072) >> 18;
                b = (chunk & 258048) >> 12;
                c = (chunk & 4032) >> 6;
                d = chunk & 63;

                base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
            }

            // Deal with the remaining bytes and padding
            if (byteRemainder === 1) {
                chunk = bytes[mainLength];

                a = (chunk & 252) >> 2; // 252 = 11111100
                b = (chunk & 3) << 4;   // 3   = 00000011

                base64 += encodings[a] + encodings[b] + '==';
            } else if (byteRemainder === 2) {
                chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

                a = (chunk & 64512) >> 10; // 64512 = 1111110000000000
                b = (chunk & 1008) >> 4;   // 1008  = 0000111110000000
                c = (chunk & 15) << 2;     // 15    = 0000000000001111

                base64 += encodings[a] + encodings[b] + encodings[c] + '=';
            }

            return base64;
        }
    }
})
</script>
<style></style>